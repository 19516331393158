




























import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName, RelatedType } from '@d24/modules'
import { IModal, ModalType } from '@movecloser/front-core'

import { AbstractModal } from '@component/Modal'
import { Identifier, Link, LinkPickerPayload } from '@/shared/contracts/data'
import { Inject } from '@/shared/plugins/inversify'
import { IRelatedService, RelatedServiceType } from '@service/related'

import { ContentModel, ContentRepositoryType, ContentType, IContentRepository } from '../contracts'
import { ContentPickerPage } from './ContentPickerPage.vue'
import { ContentPickerArticle } from './ContentPickerArticle.vue'
import { LinkPickerExternal } from './LinkPickerExternal.vue'
import { ContentLinksType } from '@module/content/maps/smart'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component<LinkPickerModal>({
  name: 'LinkPickerModal',
  components: {
    ContentPickerArticle,
    ContentPickerPage
  },

  mounted () {
    // TODO fix - see src/modules/content/components/AddSetItemModal.vue:117
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'

    if (this.payload.selected) {
      const url = this.payload.selected.url

      if (url.startsWith('article:')) {
        if (this.payload.selected.name) {
          this.externalLink.name = this.payload.selected.name
        }
        const id = Number(url.split(':')[1])
        this.select(id, RelatedType.Content)
      } else {
        this.externalLink = { ...this.payload.selected }
      }
    }
  }
})
export class LinkPickerModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: LinkPickerPayload

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public icons = DashmixIconName

  public activeTab: string = this.tabs[0]?.tab?.id || ''
  public isLoading: boolean = true
  protected initialSelected: ContentModel | null = null
  protected selected: ContentModel | null = null
  protected externalLink: Link = { name: this.payload.config?.name || '', url: '' }

  public get isReady (): boolean {
    return (this.externalLink.name !== '' && this.externalLink.url !== '' && this.activeTab === this.tabs[0].tab.id) ||
      (!!this.selected && this.activeTab !== this.tabs[0].tab.id)
  }

  public get tabs () {
    return [{
      tab: {
        id: 'link',
        label: this.$t('content.picker.link')
      },
      component: LinkPickerExternal,
      props: {
        model: this.externalLink,
        onUpdateName: (newName: string) => {
          this.externalLink.name = newName
        },
        onUpdateUrl: (newUrl: string) => {
          this.externalLink.url = newUrl
        }
      }
    }, {
      tab: {
        id: ContentType.Page,
        label: this.$t('content.picker.page')
      },
      component: ContentPickerPage,
      props: {
        selectedId: this.initialSelected?.id,
        onSelect: this.select
      }
    }, {
      tab: {
        id: ContentType.Article,
        label: this.$t('content.picker.article')
      },
      component: ContentPickerArticle,
      props: {
        selected: this.initialSelected,
        onSelect: this.select
      }
    }]
  }

  public apply (): void {
    if (this.activeTab !== this.tabs[0].tab.id && this.selected) {
      this.payload.onSelection({
        name: this.externalLink.name ? this.externalLink.name : this.selected.title,
        url: this.selected.fullSlug()
      })
    } else if (this.externalLink.url !== '') {
      this.payload.onSelection(this.externalLink)
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public select (id: Identifier, related: ContentLinksType | null) {
    this.contentRepository.loadNode(ContentType.Page, id, {})
      .then(model => {
        this.selected = model

        if (related) {
          this.initialSelected = model
        }
        if (!this.externalLink.name) {
          this.externalLink.name = this.selected.title
        }
        this.externalLink.url = this.selected.fullSlug()
      })
      .catch(e => console.error(e))
  }
}

export default LinkPickerModal
